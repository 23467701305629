import React, { useState } from 'react'
import { Invoice } from '../../../types'
import dayjs from 'dayjs'
import { formatPrice } from '../../../utils/formatPrice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setToast } from '../../../store/reducers/toastReducer'
import SkeletonTableRow from '../../../components/loaders/skeleton/SkeletonTableRow'

interface InvoicesTableProps {
  handleSorting: (orderType: string) => void
  invoices: Invoice[]
  dueDateIcon: string
  creationDate: string
  isLoading: boolean
  perPage: number
}

const InvoicesTable = ({ handleSorting, invoices, dueDateIcon, creationDate, isLoading, perPage }: InvoicesTableProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const [isExporting, setIsExporting] = useState(false)
  const [, setSelectedInvoice] = useState<Invoice | null>(null)
  const [exportingInvoiceIds, setExportingInvoiceIds] = useState<string[]>([])

  const dispatch = useAppDispatch()

  const token = currentUser?.token

  const pdfExt = 'pdf'
  const baseURL = process.env.REACT_APP_PDF_SERVICE_API_URL

  const handlePdfDownload = (invoice: Invoice) => {
    const shippingAddress = invoice.shippingAddressRequests[0]
    const billingAddress = invoice.billingAddressRequests[0]
    setIsExporting(true)
    const download = {
      invoiceNumber: invoice.invoiceNumber,
      orderNumber: invoice.postedOrderId,
      documentDate: invoice.createdAt,
      dueDate: invoice.dueDate,
      deliveryDate: invoice.deliveryDate,
      costCenter: invoice.costCenter,
      invoiceItems: invoice.orderLineRequests.map((item) => ({
        articleName: item.itemName,
        articleNumber: item.articleNumber,
        taxRate: item.itemVAT,
        quantity: item.quantity,
        price: item.netPurchasePrice,
        total: item.itemNetSale
      })),
      shippingAddress: {
        company: shippingAddress.company,
        street: shippingAddress.street,
        city: shippingAddress.place,
        state: shippingAddress.state,
        zip: shippingAddress.zipCode,
        country: shippingAddress.country
      },
      billingAddress: {
        company: billingAddress.company,
        street: billingAddress.street,
        city: billingAddress.place,
        state: billingAddress.state,
        zip: billingAddress.zipCode,
        country: billingAddress.country
      },
      totalNet: invoice.totalNet,
      vat: invoice.totalVat,
      totalAmount: invoice.totalGross
    }
    if (token) {
      fetch(`${baseURL}/api/invoices/download`, {
        method: 'POST',
        body: JSON.stringify({ download }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob()
          } else {
            throw new Error('Failed to download the PDF')
          }
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `big-little-things-invoice-${invoice.invoiceNumber}-${Date.now()}.${pdfExt}`
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
        .catch((error) => {
          const payload = {
            title: 'Error',
            message: error.message,
            isVisible: true,
            timestamp: dayjs().format('LT'),
            type: 'danger'
          }
          dispatch(setToast(payload))
        }).finally(() => {
          setIsExporting(false)
          setExportingInvoiceIds(exportingInvoiceIds.filter((id) => id !== invoice.id))
        })
    }
  }

  return (
    <table className="table table-hover table-centered table-nowrap align-middle">
      <thead>
        <tr>
          <th scope="col" className="text-nowrap">Invoice Number</th>
          <th scope="col" className="text-nowrap">Order Number</th>
          <th scope="col" className="text-center">Status</th>
          <th scope="col" className="text-nowrap cursor-pointer" onClick={() => handleSorting('dueDate')}>Due date <i className={`${dueDateIcon}`}></i></th>
          <th scope="col" className="text-nowrap cursor-pointer" onClick={() => handleSorting('creationDate')}>Creation Date <i className={`${creationDate}`}></i></th>
          <th scope="col" className="text-nowrap">Total Amount</th>
          <th scope="col" className="text-nowrap">Already Paid</th>
          <th scope="col" className="text-nowrap">Currency</th>
          <th scope="col" className="text-nowrap">Cost Center</th>
          <th scope="col" className="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
      {
        (isLoading)
          ? (
              Array.from(Array(perPage).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={10} actionQuantity={1} />)
            )
          : (
              invoices.length === 0
                ? (
                  <tr>
                    <td colSpan={10} className="text-center">No invoices available yet</td>
                  </tr>
                  )
                : (
                    invoices.map((invoice) => (
                      <tr key={invoice.id}>
                        <td className="small">{invoice.invoiceNumber}</td>
                        <td className="small">{invoice.postedOrderId}</td>
                        <td className="text-center">
                          <span className={`badge ${invoice.status === 'open' ? 'bg-secondary' : invoice.status === 'closed' ? 'bg-danger' : ''}`}>
                            {invoice.status}
                          </span>
                        </td>
                        <td className="text-nowrap">{dayjs.utc(invoice.dueDate).local().format('LL')}</td>
                        <td className="text-nowrap">{dayjs.utc(invoice.createdAt).local().format('LL')}</td>
                        <td>{formatPrice(invoice.currency || 'EUR', navigator.language).format(invoice.totalGross)}</td>
                        <td>{formatPrice(invoice.currency || 'EUR', navigator.language).format(invoice.amountPaid)}</td>
                        <td>{invoice.currency || 'EUR'}</td>
                        <td>{invoice.costCenter ?? '---'}</td>
                        <td className="text-end">
                          <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                            <button
                              className="btn btn-outline-primary btn-round"
                              onClick={() => {
                                handlePdfDownload(invoice)
                                setSelectedInvoice(invoice)
                                setExportingInvoiceIds([...exportingInvoiceIds, invoice.id])
                              }}
                              disabled={isExporting && exportingInvoiceIds.includes(invoice.id)}
                            >
                              <i className={isExporting && exportingInvoiceIds.includes(invoice.id) ? 'bi bi-hourglass-split' : 'bi bi-download'}></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )
            )
      }
      </tbody>
    </table>
  )
}

export default InvoicesTable
