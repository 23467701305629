import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { object, string } from 'yup'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Progress from '../../components/loaders/Progress'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  COMPANY_DOMAIN_UNVERIFIED_MESSAGE,
  EMAIL_UNVERIFIED_MESSAGE,
  UNAUTHORIZED_401_MESSAGE
} from '../../constants/messages'
import { setToast } from '../../store/reducers/toastReducer'
import Pagination from '../../components/Pagination'
import { getCompanyInvoice } from '../../store/reducers/api/companyReducer'
import useDebounce from '../../utils/hooks/useDebounce'
import InvoicesTable from './components/InvoicesTable'

dayjs.extend(localizedFormat)

const Invoices = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const invoices = useAppSelector((state) => state.apiCompany.invoices)

  const metadata = useAppSelector((state) => state.apiCompany.invoicesMetadata)
  const error = useAppSelector((state) => state.apiProduct.error)

  const sortOrders = ['', 'acs', 'desc']
  const [dueDateIcon, setDueDateIcon] = useState('bi bi-sort')
  const [creationDate, setCreationDate] = useState('bi bi-sort')
  const [filterOrder, setFilterOrder] = useState(0)
  const [orderBy, setOrderBy] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const companyId = currentUser?.company?.id
  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
    localStorage.setItem('perPageInventory', event.target.value)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    localStorage.setItem('pageInventory', String(page))
  }

  const searchSchema = object({
    search: string().max(48, 'Search Name is too long')
  })

  useEffect(() => {
    if (error && error.message === UNAUTHORIZED_401_MESSAGE) {
      dispatch(setToast({
        title: 'Session Expired',
        message: 'Refresh your session',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }))
    }
    if (error?.errors?.message === EMAIL_UNVERIFIED_MESSAGE) {
      dispatch(setToast({
        title: 'Verification Pending',
        message: 'Please verify your email address in the Profile section',
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }))
    }

    if (error?.errors?.message === COMPANY_DOMAIN_UNVERIFIED_MESSAGE) {
      dispatch(setToast({
        title: 'Verification Pending',
        message: "Please verify your company domain in the 'My Company' section",
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }))
    }
  }, [error, dispatch])

  const handleInventoryRefresh = async () => {
    const controller = new AbortController()
    const signal = controller.signal
    await dispatch(getCompanyInvoice({
      perPage,
      page,
      search: debouncedSearchTerm,
      signal,
      orderBy: orderBy ? 'sortBy[dueDate]' : 'sortBy[createdAt]',
      filter: sortOrders[filterOrder]
    }))
  }

  useEffect(() => {
    handleInventoryRefresh()
  }, [page, perPage, debouncedSearchTerm, companyId, token])

  const handleSorting = (orderType:string) => {
    if (orderType === 'dueDate') { setOrderBy(1) } else { setOrderBy(0) }
    if (filterOrder === 1) {
      setFilterOrder(2)
    } else {
      setFilterOrder(1)
    }
    getSortingIcon(orderType)
  }

  const getSortingIcon = (orderType:string) => {
    if (orderType === 'dueDate') {
      setCreationDate('bi bi-sort')
      if (filterOrder === 1) {
        setDueDateIcon('bi bi-sort-up')
      } else if (filterOrder === 2) {
        setDueDateIcon('bi bi-sort-down')
      } else {
        setDueDateIcon('bi bi-sort')
      }
    } else {
      setDueDateIcon('bi bi-sort')
      if (filterOrder === 1) {
        setCreationDate('bi bi-sort-up')
      } else if (filterOrder === 2) {
        setCreationDate('bi bi-sort-down')
      } else {
        setCreationDate('bi bi-sort')
      }
    }
  }

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5">Invoices</p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0 d-flex align-items-center">
                <div className="d-none d-md-flex align-items-center ms-auto me-3 my-2">
                  <Formik
                    validationSchema={searchSchema}
                    enableReinitialize
                    initialValues={{ search: '' }}
                    onSubmit={({ search }, actions) => {
                      setSearchTerm(search)
                      if (page !== 1) {
                        setPage(1)
                        navigate('')
                      }
                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit} className="d-flex">
                        <div className="input-group">
                          <input
                            onChange={(event) => {
                              handleChange(event)
                              const search = event.target.value
                              setSearchTerm(search)
                              if (page !== 1) {
                                setPage(1)
                                navigate('')
                              }
                            }}
                            maxLength={48}
                            onBlur={handleBlur}
                            value={values.search}
                            className={`form-control ${errors.search && touched.search ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Search..."
                            aria-label="Search"
                            aria-describedby="btnNavbarSearch"
                            name="search"
                          />
                          <button
                            className="btn btn-outline-dark"
                            id="btnNavbarSearch"
                            type="submit"
                            title="Search"
                            disabled={isSubmitting}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
                <button
                  type="button"
                  title="Refresh"
                  aria-label="Refresh"
                  className="btn btn-outline-dark me-2"
                  onClick={() => handleInventoryRefresh()}
                >
                  <i className="fas fa-redo"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary d-none"
                  data-bs-toggle="modal"
                  data-bs-target="#companyAddressModal"
                  title="Download All"
                  aria-label="Download All"
                >
                  <i className="bi bi-download"></i>
                  <span className="ms-1 d-none d-md-inline-block">Download All</span>
                </button>
              </ul>
            </div>
            {isLoading ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <InvoicesTable
                handleSorting={handleSorting}
                invoices={invoices}
                dueDateIcon={dueDateIcon}
                creationDate={creationDate}
                isLoading={isLoading}
                perPage={perPage}
              />
            </div>
            <Pagination
              isLoading={isLoading}
              metadata= {{ limit: metadata.perPage, offset: metadata.page, total: metadata.total }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              perPageOptions={[10, 25, 50, 100]}
              module="Invoices"
              isTrackingPage={true}
            />

          </div>
        </div>
      </div>
    </main>
  )
}

export default Invoices
