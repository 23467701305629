import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import ShoppingCart from './ShoppingCart'
import ShoppingShippingDetails from './ShoppingShippingDetails'
import { dismissModal } from '../../utils/dismissModal'
import { openModal } from '../../utils/openModal'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ShoppingDeliveryAddressPicker from './ShoppingDeliveryAddressPicker'
import { Address, ShoppingCartAddress, ShoppingCartBundle } from '../../types'
import { resetShoppingAddresses, resetShoppingCart, setShoppingAddresses } from '../../store/reducers/shoppingCartReducer'
import ShoppingDeliveryAddressEditor from './ShoppingDeliveryAddressEditor'
import ShoppingDetailsAssignArticles from './ShoppingDetailsAssignArticles'
import AddressEditor from './AddressEditor'
import { updateUserAddress } from '../../store/reducers/api/usersReducer'
import Progress from '../loaders/Progress'
import { setToast } from '../../store/reducers/toastReducer'
import ShoppingPayment from './ShoppingPayment'
import Terms from '../Terms'
import BundleConfiguratorProductPicker from './BundleConfigurator/BundleConfiguratorProductPicker'
import {
  allowedFillingMaterialTagNames,
  allowedGreetingCardsTagNames,
  allowedPackagingTagNames,
  allowedTissuePaperTagNames
} from '../../utils/allowedBundleTags'
import ShoppingOrderConfirmation from './ShoppingOrderConfirmation'

interface ShoppingModalsProps {
  setSelectedAddress: React.Dispatch<React.SetStateAction<Partial<Address> | null>>
  selectedAddress: Partial<Address> | null
  isAllowedToWriteDeliveryAddresses: boolean
  loadCostCenterOptionsDebounced: any
}
const ShoppingModals = ({
  setSelectedAddress,
  selectedAddress,
  isAllowedToWriteDeliveryAddresses,
  loadCostCenterOptionsDebounced
}: ShoppingModalsProps) => {
  const profile = useAppSelector((state) => state.profile.profile)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const isBulkOrder = useAppSelector((state) => state.shoppingCart.isBulkOrder)
  const isLoadingUserAddress = useAppSelector((state) => state.apiUsers.isLoading)
  const legalTexts = useAppSelector((state) => state.apiCompany.legalTexts)

  const [selectedAssignmentAddress, setSelectedAssignmentAddress] = useState<ShoppingCartAddress | null>(null)
  const [shippingMode, setShippingMode] = useState<'inventory' | 'delivery' | null>('delivery')
  const [areBundlesValid, setAreBundlesValid] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<Partial<Address> | null>(null)

  const billingOrbillingAndDeliveryAddress = profile?.addresses?.find(address => address.type === 'billing' || address.type === 'billingAndDelivery')

  const dispatch = useAppDispatch()

  const totalAssignedArticles = () => {
    return shoppingCartAddresses
      .flatMap(cartAddress => cartAddress.assignedLeftOverProductIds.length)
      .reduce((acc, curr) => acc + curr, 0)
  }

  const handleConfirmAndPay = () => {
    if (shoppingCartAddresses.length === 1) {
      openModal('shoppingPaymentModal')
      dismissModal('shoppingShippingDetailsModal')
      return
    }

    const assignedArticles = totalAssignedArticles()
    const totalProducts = shoppingCartProducts.length

    if (assignedArticles === 0 || assignedArticles !== totalProducts) {
      const message = assignedArticles === 0
        ? 'Assign the articles to a delivery address'
        : 'Assign the remaining articles to a delivery address'

      dispatch(setToast({
        title: 'Warning',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'warning',
        delay: 2000
      }))
    } else {
      openModal('shoppingPaymentModal')
      dismissModal('shoppingShippingDetailsModal')
    }
  }

  const validateShoppingCartBundles = (shoppingCartBundles: ShoppingCartBundle[]): boolean => {
    if (shoppingCartBundles.length === 0) return false

    const categoryMap = [
      { tags: allowedPackagingTagNames },
      { tags: allowedFillingMaterialTagNames },
      { tags: allowedGreetingCardsTagNames },
      { tags: allowedTissuePaperTagNames }
    ]
    return shoppingCartBundles.every(bundle => {
      let hasPackaging = false
      let hasFillingMaterial = false
      let hasBundleContent = false

      bundle.products.forEach(product => {
        const productTags = product.productTags.map(tag => tag.productCategoryTag.name.toLowerCase())

        const category = categoryMap.find(cat => productTags.some(tag => cat.tags.includes(tag)))

        if (category) {
          if (category.tags === allowedPackagingTagNames) {
            hasPackaging = true
          } else if (category.tags === allowedFillingMaterialTagNames) {
            hasFillingMaterial = true
          }
        } else {
          hasBundleContent = true
        }
      })

      return hasPackaging && hasFillingMaterial && hasBundleContent
    })
  }

  useEffect(() => {
    setAreBundlesValid(validateShoppingCartBundles(shoppingCartBundles))
  }, [shoppingCartBundles])

  useEffect(() => {
    setSelectedBillingAddress(billingOrbillingAndDeliveryAddress as ShoppingCartAddress | null)
  }, [billingOrbillingAndDeliveryAddress])

  return (
    <>
      <div className="modal fade" id="shoppingCartModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingCartModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingCartModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingCart />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (shoppingCartProducts.length > 0) {
                    if (!isBulkOrder && !areBundlesValid) {
                      const toastPayload = {
                        title: 'Warning',
                        message: 'Please ensure the bundles are complete.',
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'warning',
                        delay: 2000
                      }
                      dispatch(setToast(toastPayload))
                      return
                    }
                    dismissModal('shoppingCartModal')
                    openModal('shoppingShippingDetailsModal')
                  } else {
                    const toastPayload = {
                      title: 'Warning',
                      message: 'Please ensure your cart is not empty.',
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'warning',
                      delay: 2000
                    }
                    dispatch(setToast(toastPayload))
                  }
                }}
                disabled={shoppingCartProducts.length === 0 || (!isBulkOrder && !areBundlesValid)}
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingShippingDetailsModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingShippingDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingShippingDetailsModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingShippingDetails
                setSelectedAssignmentAddress={setSelectedAssignmentAddress}
                setSelectedAddress={setSelectedAddress}
                shippingMode={shippingMode}
                setShippingMode={setShippingMode}
                setEditMode={setEditMode}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('shoppingShippingDetailsModal')
                  openModal('shoppingCartModal')
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={shoppingCartAddresses.length === 0}
                onClick={() => handleConfirmAndPay()}
              >
                Confirm and Pay
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDeliveryAddressPickerModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDeliveryAddressPickerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDeliveryAddressPickerModalLabel"><i className="bi bi-truck me-1"></i>Shipping Details - Select delivery address</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoadingUserAddress ? <Progress marginBottom={false} /> : <hr className="border border-1 opacity-50 m-0"></hr>}
            <div className="modal-body">
              <ShoppingDeliveryAddressPicker
                isLoading={isLoadingUserAddress}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                isAllowedToWriteDeliveryAddresses={isAllowedToWriteDeliveryAddresses}
              />
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  if (selectedAddress) {
                    setSelectedAddress(null)
                    const updatedAddresses = shoppingCartAddresses.filter(address => address.id !== selectedAddress.id)
                    dispatch(setShoppingAddresses({ addresses: updatedAddresses }))
                  }
                }}
                disabled={!selectedAddress}
              >
                Reset Selection
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    dismissModal('shoppingDeliveryAddressPickerModal')
                    openModal('shoppingShippingDetailsModal')
                    if (selectedAddress) {
                      const updatedAddresses = shoppingCartAddresses.filter(address => address.id !== selectedAddress.id)
                      const payload = {
                        addresses: updatedAddresses
                      }
                      dispatch(setShoppingAddresses(payload))
                      setSelectedAddress(null)
                    }
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    dismissModal('shoppingDeliveryAddressPickerModal')
                    openModal('shoppingDeliveryAddressEditorModal')
                  }}
                  disabled={!selectedAddress}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDeliveryAddressEditorModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDeliveryAddressEditorModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDeliveryAddressEditorModalLabel"><i className="bi bi-cart me-1"></i>Shipping Details - Add delivery address</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingDeliveryAddressEditor
                shippingAddress={selectedAddress}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                setSelectedDeliveryAddress={setSelectedAddress}
                setSelectedAddress={setSelectedAddress}
                editMode={editMode}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDetailsAssignArticlesModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDetailsAssignArticlesModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDetailsAssignArticlesModalLabel"><i className="bi bi-cart me-1"></i>Shopping Details - Assign Articles</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {
                selectedAssignmentAddress
                  ? (<ShoppingDetailsAssignArticles selectedAssignmentAddress={selectedAssignmentAddress} setSelectedAssignmentAddress={setSelectedAssignmentAddress} />)
                  : (<p>No assignment address selected</p>)
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('shoppingDetailsAssignArticlesModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dismissModal('shoppingDetailsAssignArticlesModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Assign Articles
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="addressEditorModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="addressEditorModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addressEditorModalLabel"><i className="bi bi-cart me-1"></i>Shopping Details - Delivery Address Editor</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoadingUserAddress ? <Progress marginBottom={false} /> : <hr className="border border-1 opacity-50 m-0"></hr>}
            <div className="modal-body">
              <AddressEditor
                initialAddress={selectedAddress}
                id={profile?.id || ''}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                save={updateUserAddress}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingPaymentModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingPaymentModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingPaymentModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <hr className="border border-1 opacity-50 m-0"></hr>
            <div className="modal-body">
              <ShoppingPayment
                billingAddress={selectedBillingAddress}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                shippingMode={shippingMode}
                setSelectedBillingAddress={setSelectedBillingAddress}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="termsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="termsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3" id="termsModalLabel">Terms and Conditions</h1>
              <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#shoppingPaymentModal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Terms terms={legalTexts.find(legalText => legalText.type === 'terms')?.template} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shoppingPaymentModal">Understood</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bundleConfiguratorProductPickerModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="bundleConfiguratorProductPickerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="bundleConfiguratorProductPickerModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <BundleConfiguratorProductPicker />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('bundleConfiguratorProductPickerModal')
                  openModal('shoppingCartModal')
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingOrderConfirmationModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingOrderConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingOrderConfirmationModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => {
                  dispatch(resetShoppingCart())
                  dispatch(resetShoppingAddresses())
                }}
              ></button>
            </div>
            <div className="modal-body">
              <ShoppingOrderConfirmation selectedBillingAddress={selectedBillingAddress} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShoppingModals
